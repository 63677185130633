<template>
  <div>
    <v-data-table :headers="headers" :items="versions" class="elevation-1">
      <template v-slot:top>
        <v-alert dense v-model="alert" type="success">
          Changes saved successfully.
        </v-alert>
        <v-toolbar flat>
          <v-toolbar-title>Versions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Version
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="editedItem.title"
                        label="Title"
                        :error-messages="errors.title"
                        error-count="3"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"> </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <ConfirmDelete
            v-if="deleteItem"
            :item="deleteItem"
            :delete-action="deleteAction"
            @close="deleteItem = null"
          />
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem = item"> mdi-delete </v-icon>
      </template>
      <template v-slot:no-data>
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GET_VERSIONS } from "@/store/versions.module";
import { CREATE_VERSION } from "@/store/versions.module";
import { UPDATE_VERSION } from "@/store/versions.module";
import { DELETE_VERSION } from "@/store/versions.module";
import ConfirmDelete from "@/components/modals/ConfirmDelete";

export default {
  name: "List",
  components: { ConfirmDelete },
  mounted() {
    this.fetchVersions();
  },
  data: () => ({
    alert: false,
    dialog: false,
    deleteAction: DELETE_VERSION,
    deleteItem: null,
    headers: [
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Email",
        value: "email",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ],
    editedIndex: -1,
    editedItem: {
      id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    defaultItem: {
      id: "",
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    showPassword: false,
    showPasswordConfirmation: false,
  }),
  computed: {
    ...mapState({
      versions: (state) => state.versions.versions,
      errors: (state) => state.versions.errors,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "New Version" : "Edit Version";
    },
    passwordMinCharactersRule() {
      return () =>
        typeof this.editedItem.password === "undefined" ||
        this.editedItem.password.length === 0 ||
        this.editedItem.password.length >= 8 ||
        "Min 8 characters";
    },
  },
  watch: {
    alert(new_val) {
      if (new_val) {
        setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },
    dialog(val) {
      val || this.close();
    },
  },

  created() {},
  methods: {
    fetchVersions() {
      this.$store.dispatch(GET_VERSIONS);
    },
    editItem(item) {
      this.editedIndex = this.versions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      Object.keys(this.errors).forEach((key) => {
        delete this.errors[key];
      });
    },

    displayAlert() {
      this.alert = true;
    },

    save() {
      let action = CREATE_VERSION;

      if (this.editedIndex > -1) {
        action = UPDATE_VERSION;
      }

      this.$store
        .dispatch(action, this.editedItem)
        .then(this.close)
        .then(this.displayAlert);
    },
  },
};
</script>

<style scoped></style>
